import {jobTypeCodes} from '@/enums/jobTypeCodes'
import {primitiveUserSettingCodes} from "@/enums/primitiveUserSettingCodes";
import {dashboardOrderStatuses} from "@/enums/dashboardOrderStatuses";

const initState = () => ({
  jobOrders: [],
  salesOrders: [],
  priceQuotes: [],
  invoices: [],
  currentJobOrders: [],
  finishedJobOrders: [],
  joOrderPage: 0,
  currentJoOrderPage: 0,
  finishedJoOrderPage: 0,
  totalJobOrders: 0,
  salesOrderPage: 0,
  totalSalesOrders: 0,
  priceQuotePage: 0,
  totalPriceQuotes: 0,
  invoicePage: 0,
  totalInvoices: 0,
  JOLoading: false,
  CurrentJOLoading: false,
  FinishedJOLoading: false,
  SOLoading: false,
  PQLoading: false,
  InvoiceLoading: false,
  JOFinished: false,
  SOFinished: false,
  PQFinished: false,
  InvoiceFinished: false,
  messages: [],
  balanceInfo: {},
  metals: [],
  featured: [],
  jobOrderSummaries: [],
  priceQuoteSummaries: [],
  salesOrderSummaries: [],
  invoiceSummaries: [],
  currentJobOrderSummaries: [],
  finishedJobOrderSummaries: [],
})

export const state = initState

export const mutations = {
  setMessages(state, {messages}) {
    state.messages = messages
  },
  setBalanceInfo(state, {balanceInfo}) {
    state.balanceInfo = balanceInfo
  },
  setJobOrders(state, {orders}) {
    if (orders) {
      orders.forEach((jo) => state.jobOrders.push(jo))
    }
  },
  setSalesOrders(state, {orders}) {
    if (orders) {
      orders.forEach((jo) => state.salesOrders.push(jo))
    }
  },
  setPriceQuotes(state, {orders}) {
    if (orders) {
      orders.forEach((jo) => state.priceQuotes.push(jo))
    }
  },
  setInvoices(state, {invoices}) {
    if (invoices) {
      invoices.forEach((jo) => state.invoices.push(jo))
    }
  },
  setCurrentJobOrders(state, {orders}) {
    if (orders) {
      orders.forEach((jo) => state.currentJobOrders.push(jo))
    }
  },
  setFinishedJobOrders(state, {orders}) {
    if (orders) {
      orders.forEach((jo) => state.finishedJobOrders.push(jo))
    }
  },
  setMetals(state, {metals}) {
    state.metals = metals
  },
  setFeatured(state, {featured}) {
    state.featured = featured
  },
  setJobOrderSummaries(state, {jobOrderSummaries}) {
    state.jobOrderSummaries = jobOrderSummaries
  },
  setPriceQuoteSummaries(state, {priceQuoteSummaries}) {
    state.priceQuoteSummaries = priceQuoteSummaries
  },
  setSalesOrderSummaries(state, {salesOrderSummaries}) {
    state.salesOrderSummaries = salesOrderSummaries
  },
  setInvoiceSummaries(state, {invoiceSummaries}) {
    state.invoiceSummaries = invoiceSummaries
  },
  setCurrentJobOrderSummaries(state, {currentJobOrderSummaries}) {
    state.currentJobOrderSummaries = currentJobOrderSummaries
  },
  setFinishedJobOrderSummaries(state, {finishedJobOrderSummaries}) {
    state.finishedJobOrderSummaries = finishedJobOrderSummaries
  },
  setJOLoading(state, {isLoading}) {
    state.JOLoading = isLoading
  },
  setCurrentJOLoading(state, {isLoading}) {
    state.CurrentJOLoading = isLoading
  },
  setFinishedJOLoading(state, {isLoading}) {
    state.FinishedJOLoading = isLoading
  },
  setSOLoading(state, {isLoading}) {
    state.SOLoading = isLoading
  },
  setPQOLoading(state, {isLoading}) {
    state.PQLoading = isLoading
  },
  setInvoiceLoading(state, {isLoading}) {
    state.InvoiceLoading = isLoading
  },
  increaseJOPageIndex(state) {
    state.joOrderPage++
  },
  increaseCurrentJOPageIndex(state) {
    state.currentJoOrderPage++
  },
  increaseFinishedJOPageIndex(state) {
    state.finishedJoOrderPage++
  },
  increaseSOPageIndex(state) {
    state.salesOrderPage++
  },
  increasePQPageIndex(state) {
    state.priceQuotePage++
  },
  increaseInvoicePageIndex(state) {
    state.invoicePage++
  },
}

export const actions = {
  async getMessages({commit}) {
    const messages = await this.$axios.$get('/Dashboard/messages')
    commit('setMessages', {messages})
  },
  async getBalanceInfo({commit}) {
    const balanceInfo = await this.$axios.$get('/Dashboard/BalanceInfo')
    commit('setBalanceInfo', {balanceInfo})
  },
  async getJobOrders({commit}, {entityTypeCode, pageSize, page, jobStatusFilter = dashboardOrderStatuses.all}) {
    const orders = await this.$axios.$get(
      '/Dashboard/Orders/' +
        '?entityTypeCode=' +
        entityTypeCode +
        '&pageSize=' +
        pageSize +
        '&pageNo=' +
        page +
        '&getJobsWithStatus=' +
      jobStatusFilter
    )
    if (orders.length > 0) {
      switch (entityTypeCode) {
        case jobTypeCodes.JobOrder:
          switch (jobStatusFilter) {
            case dashboardOrderStatuses.all:
              commit('setJobOrders', {orders})
              break
            case dashboardOrderStatuses.open:
              commit('setCurrentJobOrders', {orders})
              break
            case dashboardOrderStatuses.closed:
              commit('setFinishedJobOrders', {orders})
              break
          }
          break
        case jobTypeCodes.SalesOrder:
          commit('setSalesOrders', {orders})
          break
        case jobTypeCodes.PriceQuote:
          commit('setPriceQuotes', {orders})
          break
      }
    } else {
    }
  },
  async getJobOrderSummaries({commit}) {
    const jobOrderSummaries = await this.$axios.$get(
      '/Dashboard/OrdersStatusSummary/' + jobTypeCodes.JobOrder + '?GetJobsWithStatus=' + dashboardOrderStatuses.all
    )
    commit('setJobOrderSummaries', {jobOrderSummaries})
  },
  async getCurrentJobOrderSummaries({commit}) {
    const jobOrderSummaries = await this.$axios.$get(
      '/Dashboard/OrdersStatusSummary/' + jobTypeCodes.JobOrder + '?GetJobsWithStatus=' + dashboardOrderStatuses.open
    )
    commit('setCurrentJobOrderSummaries', {currentJobOrderSummaries: jobOrderSummaries})
  },
  async getFinishedJobOrderSummaries({commit}) {
    const jobOrderSummaries = await this.$axios.$get(
      '/Dashboard/OrdersStatusSummary/' + jobTypeCodes.JobOrder + '?GetJobsWithStatus=' + dashboardOrderStatuses.closed
    )
    commit('setFinishedJobOrderSummaries', {finishedJobOrderSummaries: jobOrderSummaries})
  },
  async getPriceQuoteSummaries({commit}) {
    const priceQuoteSummaries = await this.$axios.$get(
      '/Dashboard/OrdersStatusSummary/' + jobTypeCodes.PriceQuote
    )
    commit('setPriceQuoteSummaries', {priceQuoteSummaries})
  },
  async getSalesOrderSummaries({commit}) {
    const salesOrderSummaries = await this.$axios.$get(
      '/Dashboard/OrdersStatusSummary/' + jobTypeCodes.SalesOrder
    )
    commit('setSalesOrderSummaries', {salesOrderSummaries})
  },
  async getInvoiceSummaries({commit}) {
    const invoiceSummaries = await this.$axios.$get(
      '/Dashboard/InvoiceStatusSummary'
    )
    commit('setInvoiceSummaries', {invoiceSummaries})
  },
  async getSalesOrders({commit}) {
    const salesOrders = await this.$axios.$get(
      '/Dashboard/Orders/' + jobTypeCodes.SalesOrder
    )
    commit('setSalesOrders', {salesOrders})
  },
  async getPriceQuotes({commit}) {
    const priceQuotes = await this.$axios.$get(
      '/Dashboard/Orders/' + jobTypeCodes.PriceQuote
    )
    commit('setPriceQuotes', {priceQuotes})
  },
  async getInvoices({commit}, {pageSize, page}) {
    const invoices = await this.$axios.$get(
      '/Dashboard/Invoices' + '?pageSize=' + pageSize + '&pageNo=' + page
    )
    commit('setInvoices', {invoices})
  },
  async getMetals({commit}) {
    const metals = await this.$axios.$get('/Dashboard/Metals')
    commit('setMetals', {metals})
  },
  async getFeatured({commit}) {
    const featured = await this.$axios.$get('/Dashboard/Featured')
    commit('setFeatured', {featured})
  },
  async saveDashboard({commit}, {dashboardCards}) {
    const result = await this.$axios.$post(
      '/Dashboard/Settings',
      dashboardCards
    )
  },
  async getDashboard({commit}) {
    return await this.$axios.$get('/Dashboard/Settings')
  },
  async saveDashboardLayout({commit}, {dashboardLayout}) {
    const result = await this.$axios.$post(
      '/Dashboard/PrimitiveSetting',
      { Code: primitiveUserSettingCodes.CPV3_DASHBOARD_GRID_LAYOUT, Value: dashboardLayout })
  },
  async getDashboardLayout({commit}) {
    return await this.$axios.$get('/Dashboard/PrimitiveSetting/' + primitiveUserSettingCodes.CPV3_DASHBOARD_GRID_LAYOUT)
  },
}
