import Vue from 'vue'

const components = {
  NuxtDynamic: () => import('../..\\node_modules\\@blokwise\\dynamic\\src\\components\\Dynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(c => c.default || c),
  CmsComponentAdd: () => import('../..\\components\\backoffice\\cms\\component-add.vue' /* webpackChunkName: "components/cms-component-add" */).then(c => c.default || c),
  CmsComponentFieldEditDialog: () => import('../..\\components\\backoffice\\cms\\component-field-edit-dialog.vue' /* webpackChunkName: "components/cms-component-field-edit-dialog" */).then(c => c.default || c),
  CmsComponentFilterButton: () => import('../..\\components\\backoffice\\cms\\component-filter-button.vue' /* webpackChunkName: "components/cms-component-filter-button" */).then(c => c.default || c),
  CmsComponentTable: () => import('../..\\components\\backoffice\\cms\\component-table.vue' /* webpackChunkName: "components/cms-component-table" */).then(c => c.default || c),
  CmsContentAdd: () => import('../..\\components\\backoffice\\cms\\content-add.vue' /* webpackChunkName: "components/cms-content-add" */).then(c => c.default || c),
  CmsContentField: () => import('../..\\components\\backoffice\\cms\\content-field.vue' /* webpackChunkName: "components/cms-content-field" */).then(c => c.default || c),
  CmsContentsTable: () => import('../..\\components\\backoffice\\cms\\contents-table.vue' /* webpackChunkName: "components/cms-contents-table" */).then(c => c.default || c),
  CmsExpandingFilterButton: () => import('../..\\components\\backoffice\\cms\\expanding-filter-button.vue' /* webpackChunkName: "components/cms-expanding-filter-button" */).then(c => c.default || c),
  CmsFileInfo: () => import('../..\\components\\backoffice\\cms\\file-info.vue' /* webpackChunkName: "components/cms-file-info" */).then(c => c.default || c),
  CmsFolderAdd: () => import('../..\\components\\backoffice\\cms\\folder-add.vue' /* webpackChunkName: "components/cms-folder-add" */).then(c => c.default || c),
  CmsImageCropper: () => import('../..\\components\\backoffice\\cms\\image-cropper.vue' /* webpackChunkName: "components/cms-image-cropper" */).then(c => c.default || c),
  CmsSectionField: () => import('../..\\components\\backoffice\\cms\\section-field.vue' /* webpackChunkName: "components/cms-section-field" */).then(c => c.default || c),
  ConfigurationAdmColumnsAutoComplete: () => import('../..\\components\\backoffice\\configuration\\adm-columns-auto-complete.vue' /* webpackChunkName: "components/configuration-adm-columns-auto-complete" */).then(c => c.default || c),
  ConfigurationCustomerOrderSettings: () => import('../..\\components\\backoffice\\configuration\\customer-order-settings.vue' /* webpackChunkName: "components/configuration-customer-order-settings" */).then(c => c.default || c),
  ConfigurationFieldRows: () => import('../..\\components\\backoffice\\configuration\\field-rows.vue' /* webpackChunkName: "components/configuration-field-rows" */).then(c => c.default || c),
  ConfigurationFieldSettingDialog: () => import('../..\\components\\backoffice\\configuration\\field-setting-dialog.vue' /* webpackChunkName: "components/configuration-field-setting-dialog" */).then(c => c.default || c),
  ConfigurationFieldSettings: () => import('../..\\components\\backoffice\\configuration\\field-settings.vue' /* webpackChunkName: "components/configuration-field-settings" */).then(c => c.default || c),
  ConfigurationGlobalSettings: () => import('../..\\components\\backoffice\\configuration\\global-settings.vue' /* webpackChunkName: "components/configuration-global-settings" */).then(c => c.default || c),
  ConfigurationGroupSettingsDialog: () => import('../..\\components\\backoffice\\configuration\\group-settings-dialog.vue' /* webpackChunkName: "components/configuration-group-settings-dialog" */).then(c => c.default || c),
  ConfigurationModules: () => import('../..\\components\\backoffice\\configuration\\modules.vue' /* webpackChunkName: "components/configuration-modules" */).then(c => c.default || c),
  ConfigurationRoleRows: () => import('../..\\components\\backoffice\\configuration\\role-rows.vue' /* webpackChunkName: "components/configuration-role-rows" */).then(c => c.default || c),
  ConfigurationRoleSettingDialog: () => import('../..\\components\\backoffice\\configuration\\role-setting-dialog.vue' /* webpackChunkName: "components/configuration-role-setting-dialog" */).then(c => c.default || c),
  ConfigurationWorkflows: () => import('../..\\components\\backoffice\\configuration\\workflows.vue' /* webpackChunkName: "components/configuration-workflows" */).then(c => c.default || c),
  DashboardHeader: () => import('../..\\components\\backoffice\\dashboard\\dashboard-header.vue' /* webpackChunkName: "components/dashboard-header" */).then(c => c.default || c),
  DashboardNewArrival: () => import('../..\\components\\backoffice\\dashboard\\dashboard-new-arrival.vue' /* webpackChunkName: "components/dashboard-new-arrival" */).then(c => c.default || c),
  DashboardSummaryTable: () => import('../..\\components\\backoffice\\dashboard\\dashboard-summary-table.vue' /* webpackChunkName: "components/dashboard-summary-table" */).then(c => c.default || c),
  DashboardTable: () => import('../..\\components\\backoffice\\dashboard\\dashboard-table.vue' /* webpackChunkName: "components/dashboard-table" */).then(c => c.default || c),
  DashboardMarketTable: () => import('../..\\components\\backoffice\\dashboard\\market-table.vue' /* webpackChunkName: "components/dashboard-market-table" */).then(c => c.default || c),
  DashboardMessagesTable: () => import('../..\\components\\backoffice\\dashboard\\messages-table.vue' /* webpackChunkName: "components/dashboard-messages-table" */).then(c => c.default || c),
  FinancialCreditCardDialog: () => import('../..\\components\\backoffice\\financial\\credit-card-dialog.vue' /* webpackChunkName: "components/financial-credit-card-dialog" */).then(c => c.default || c),
  FinancialCustomerCreditTable: () => import('../..\\components\\backoffice\\financial\\customer-credit-table.vue' /* webpackChunkName: "components/financial-customer-credit-table" */).then(c => c.default || c),
  FinancialInvoiceTable: () => import('../..\\components\\backoffice\\financial\\invoice-table.vue' /* webpackChunkName: "components/financial-invoice-table" */).then(c => c.default || c),
  FinancialPaymentDialog: () => import('../..\\components\\backoffice\\financial\\payment-dialog.vue' /* webpackChunkName: "components/financial-payment-dialog" */).then(c => c.default || c),
  FinancialPaymentsTable: () => import('../..\\components\\backoffice\\financial\\payments-table.vue' /* webpackChunkName: "components/financial-payments-table" */).then(c => c.default || c),
  MediaLibraryAddFileDialog: () => import('../..\\components\\backoffice\\media-library\\add-file-dialog.vue' /* webpackChunkName: "components/media-library-add-file-dialog" */).then(c => c.default || c),
  MediaLibraryAdminView: () => import('../..\\components\\backoffice\\media-library\\admin-view.vue' /* webpackChunkName: "components/media-library-admin-view" */).then(c => c.default || c),
  MediaLibraryChangeFolderDialog: () => import('../..\\components\\backoffice\\media-library\\change-folder-dialog.vue' /* webpackChunkName: "components/media-library-change-folder-dialog" */).then(c => c.default || c),
  MediaLibraryCustomerView: () => import('../..\\components\\backoffice\\media-library\\customer-view.vue' /* webpackChunkName: "components/media-library-customer-view" */).then(c => c.default || c),
  MediaLibraryDeleteDialog: () => import('../..\\components\\backoffice\\media-library\\delete-dialog.vue' /* webpackChunkName: "components/media-library-delete-dialog" */).then(c => c.default || c),
  MediaLibraryEditDialog: () => import('../..\\components\\backoffice\\media-library\\edit-dialog.vue' /* webpackChunkName: "components/media-library-edit-dialog" */).then(c => c.default || c),
  MediaLibraryOpenDialog: () => import('../..\\components\\backoffice\\media-library\\open-dialog.vue' /* webpackChunkName: "components/media-library-open-dialog" */).then(c => c.default || c),
  OrderCreationEstimateGrid: () => import('../..\\components\\backoffice\\order-creation\\estimate-grid.vue' /* webpackChunkName: "components/order-creation-estimate-grid" */).then(c => c.default || c),
  OrderCreationFileUploader: () => import('../..\\components\\backoffice\\order-creation\\file-uploader.vue' /* webpackChunkName: "components/order-creation-file-uploader" */).then(c => c.default || c),
  OrderCreationMultiselectDialog: () => import('../..\\components\\backoffice\\order-creation\\multiselect-dialog.vue' /* webpackChunkName: "components/order-creation-multiselect-dialog" */).then(c => c.default || c),
  ProductCatalogAddItemTypeDialog: () => import('../..\\components\\backoffice\\product-catalog\\add-item-type-dialog.vue' /* webpackChunkName: "components/product-catalog-add-item-type-dialog" */).then(c => c.default || c),
  ProductCatalogCategoryDetailTable: () => import('../..\\components\\backoffice\\product-catalog\\category-detail-table.vue' /* webpackChunkName: "components/product-catalog-category-detail-table" */).then(c => c.default || c),
  ProductCatalogCustomTablePager: () => import('../..\\components\\backoffice\\product-catalog\\custom-table-pager.vue' /* webpackChunkName: "components/product-catalog-custom-table-pager" */).then(c => c.default || c),
  ProductCatalogFilterCard: () => import('../..\\components\\backoffice\\product-catalog\\product-catalog-filter-card.vue' /* webpackChunkName: "components/product-catalog-filter-card" */).then(c => c.default || c),
  ProductCatalogTable: () => import('../..\\components\\backoffice\\product-catalog\\product-catalog-table.vue' /* webpackChunkName: "components/product-catalog-table" */).then(c => c.default || c),
  ProductCategoryImageUploader: () => import('../..\\components\\backoffice\\product-catalog\\product-category-image-uploader.vue' /* webpackChunkName: "components/product-category-image-uploader" */).then(c => c.default || c),
  ProductsProductDetail: () => import('../..\\components\\backoffice\\products\\product-detail.vue' /* webpackChunkName: "components/products-product-detail" */).then(c => c.default || c),
  QuotesQuoteDetail: () => import('../..\\components\\backoffice\\quotes\\quote-detail.vue' /* webpackChunkName: "components/quotes-quote-detail" */).then(c => c.default || c),
  QuotesQuoteTable: () => import('../..\\components\\backoffice\\quotes\\quote-table.vue' /* webpackChunkName: "components/quotes-quote-table" */).then(c => c.default || c),
  SeoCatalogTable: () => import('../..\\components\\backoffice\\seo\\seo-catalog-table.vue' /* webpackChunkName: "components/seo-catalog-table" */).then(c => c.default || c),
  SeoDetail: () => import('../..\\components\\backoffice\\seo\\seo-detail.vue' /* webpackChunkName: "components/seo-detail" */).then(c => c.default || c),
  SeoProductTable: () => import('../..\\components\\backoffice\\seo\\seo-product-table.vue' /* webpackChunkName: "components/seo-product-table" */).then(c => c.default || c),
  ShoppingCartCheckoutStepper: () => import('../..\\components\\backoffice\\shopping-cart\\checkout-stepper.vue' /* webpackChunkName: "components/shopping-cart-checkout-stepper" */).then(c => c.default || c),
  ShoppingCartCheckout: () => import('../..\\components\\backoffice\\shopping-cart\\checkout.vue' /* webpackChunkName: "components/shopping-cart-checkout" */).then(c => c.default || c),
  StatusTrackingOrderCardItem: () => import('../..\\components\\backoffice\\status-tracking\\order-card-item.vue' /* webpackChunkName: "components/status-tracking-order-card-item" */).then(c => c.default || c),
  StatusTrackingOrderCard: () => import('../..\\components\\backoffice\\status-tracking\\order-card.vue' /* webpackChunkName: "components/status-tracking-order-card" */).then(c => c.default || c),
  StatusTrackingOrderDetailInfoBox: () => import('../..\\components\\backoffice\\status-tracking\\order-detail-info-box.vue' /* webpackChunkName: "components/status-tracking-order-detail-info-box" */).then(c => c.default || c),
  StatusTrackingOrderDetail: () => import('../..\\components\\backoffice\\status-tracking\\order-detail.vue' /* webpackChunkName: "components/status-tracking-order-detail" */).then(c => c.default || c),
  StatusTrackingOrderTable: () => import('../..\\components\\backoffice\\status-tracking\\order-table.vue' /* webpackChunkName: "components/status-tracking-order-table" */).then(c => c.default || c),
  StatusTrackingSalesOrderDetail: () => import('../..\\components\\backoffice\\status-tracking\\sales-order-detail.vue' /* webpackChunkName: "components/status-tracking-sales-order-detail" */).then(c => c.default || c),
  StatusTrackingSalesOrderTable: () => import('../..\\components\\backoffice\\status-tracking\\sales-order-table.vue' /* webpackChunkName: "components/status-tracking-sales-order-table" */).then(c => c.default || c),
  FrontendAppPage: () => import('../..\\frontend\\components\\app\\page.vue' /* webpackChunkName: "components/frontend-app-page" */).then(c => c.default || c),
  FrontendLayoutFooter: () => import('../..\\frontend\\components\\layout\\footer.vue' /* webpackChunkName: "components/frontend-layout-footer" */).then(c => c.default || c),
  FrontendLayoutHeader: () => import('../..\\frontend\\components\\layout\\header.vue' /* webpackChunkName: "components/frontend-layout-header" */).then(c => c.default || c),
  FrontendPageSectionsCategorySlider: () => import('../..\\frontend\\components\\page-sections\\category-slider.vue' /* webpackChunkName: "components/frontend-page-sections-category-slider" */).then(c => c.default || c),
  FrontendPageSectionsFooterLinkList: () => import('../..\\frontend\\components\\page-sections\\footer-link-list.vue' /* webpackChunkName: "components/frontend-page-sections-footer-link-list" */).then(c => c.default || c),
  FrontendPageSectionsHomePageCard: () => import('../..\\frontend\\components\\page-sections\\home-page-card.vue' /* webpackChunkName: "components/frontend-page-sections-home-page-card" */).then(c => c.default || c),
  FrontendPageSectionsHomePageProductSlider: () => import('../..\\frontend\\components\\page-sections\\home-page-product-slider.vue' /* webpackChunkName: "components/frontend-page-sections-home-page-product-slider" */).then(c => c.default || c),
  FrontendPageSectionsProductCard: () => import('../..\\frontend\\components\\page-sections\\product-card.vue' /* webpackChunkName: "components/frontend-page-sections-product-card" */).then(c => c.default || c),
  FrontendTemplatesCatalog: () => import('../..\\frontend\\components\\templates\\catalog.vue' /* webpackChunkName: "components/frontend-templates-catalog" */).then(c => c.default || c),
  FrontendTemplatesHome: () => import('../..\\frontend\\components\\templates\\home.vue' /* webpackChunkName: "components/frontend-templates-home" */).then(c => c.default || c),
  FrontendTemplatesMainPage: () => import('../..\\frontend\\components\\templates\\main-page.vue' /* webpackChunkName: "components/frontend-templates-main-page" */).then(c => c.default || c),
  Logo: () => import('../..\\components\\Logo.vue' /* webpackChunkName: "components/logo" */).then(c => c.default || c),
  VuetifyLogo: () => import('../..\\components\\VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => c.default || c),
  CommonChangePasswordCard: () => import('../..\\components\\common\\change-password-card.vue' /* webpackChunkName: "components/common-change-password-card" */).then(c => c.default || c),
  CommonColumnUserSettingDialog: () => import('../..\\components\\common\\column-user-setting-dialog.vue' /* webpackChunkName: "components/common-column-user-setting-dialog" */).then(c => c.default || c),
  CommonConfirm: () => import('../..\\components\\common\\confirm.vue' /* webpackChunkName: "components/common-confirm" */).then(c => c.default || c),
  CommonCustomNumberInputMinMax: () => import('../..\\components\\common\\custom-number-input-min-max.vue' /* webpackChunkName: "components/common-custom-number-input-min-max" */).then(c => c.default || c),
  CommonCustomNumberInput: () => import('../..\\components\\common\\custom-number-input.vue' /* webpackChunkName: "components/common-custom-number-input" */).then(c => c.default || c),
  CommonForgotPasswordDialog: () => import('../..\\components\\common\\forgot-password-dialog.vue' /* webpackChunkName: "components/common-forgot-password-dialog" */).then(c => c.default || c),
  CommonInfoBox: () => import('../..\\components\\common\\info-box.vue' /* webpackChunkName: "components/common-info-box" */).then(c => c.default || c),
  CommonJobDeclinePopup: () => import('../..\\components\\common\\job-decline-popup.vue' /* webpackChunkName: "components/common-job-decline-popup" */).then(c => c.default || c),
  CommonLeftMenu: () => import('../..\\components\\common\\left-menu.vue' /* webpackChunkName: "components/common-left-menu" */).then(c => c.default || c),
  CommonLoginCard: () => import('../..\\components\\common\\login-card.vue' /* webpackChunkName: "components/common-login-card" */).then(c => c.default || c),
  CommonNavigationBars: () => import('../..\\components\\common\\navigation-bars.vue' /* webpackChunkName: "components/common-navigation-bars" */).then(c => c.default || c),
  CommonNmiForm: () => import('../..\\components\\common\\nmi-form.vue' /* webpackChunkName: "components/common-nmi-form" */).then(c => c.default || c),
  CommonNoteCardLayout: () => import('../..\\components\\common\\note-card-layout.vue' /* webpackChunkName: "components/common-note-card-layout" */).then(c => c.default || c),
  CommonNoteForm: () => import('../..\\components\\common\\note-form.vue' /* webpackChunkName: "components/common-note-form" */).then(c => c.default || c),
  CommonRegisterCard: () => import('../..\\components\\common\\register-card.vue' /* webpackChunkName: "components/common-register-card" */).then(c => c.default || c),
  CommonSwitchUser: () => import('../..\\components\\common\\switch-user.vue' /* webpackChunkName: "components/common-switch-user" */).then(c => c.default || c),
  CommonTableFooterExtraItems: () => import('../..\\components\\common\\table-footer-extra-items.vue' /* webpackChunkName: "components/common-table-footer-extra-items" */).then(c => c.default || c),
  CommonWebCam: () => import('../..\\components\\common\\web-cam.vue' /* webpackChunkName: "components/common-web-cam" */).then(c => c.default || c),
  SharedAttributeFields: () => import('../..\\components\\shared\\attribute-fields.vue' /* webpackChunkName: "components/shared-attribute-fields" */).then(c => c.default || c),
  SharedCardListViewToggleButton: () => import('../..\\components\\shared\\card-list-view-toggle-button.vue' /* webpackChunkName: "components/shared-card-list-view-toggle-button" */).then(c => c.default || c),
  SharedCarouselTest: () => import('../..\\components\\shared\\carousel-test.vue' /* webpackChunkName: "components/shared-carousel-test" */).then(c => c.default || c),
  SharedCloseBtn: () => import('../..\\components\\shared\\close-btn.vue' /* webpackChunkName: "components/shared-close-btn" */).then(c => c.default || c),
  SharedCustomButton: () => import('../..\\components\\shared\\custom-button.vue' /* webpackChunkName: "components/shared-custom-button" */).then(c => c.default || c),
  SharedCustomCombo: () => import('../..\\components\\shared\\custom-combo.vue' /* webpackChunkName: "components/shared-custom-combo" */).then(c => c.default || c),
  SharedCustomSwitch: () => import('../..\\components\\shared\\custom-switch.vue' /* webpackChunkName: "components/shared-custom-switch" */).then(c => c.default || c),
  SharedDarkButton: () => import('../..\\components\\shared\\dark-button.vue' /* webpackChunkName: "components/shared-dark-button" */).then(c => c.default || c),
  SharedDataTableRowHandler: () => import('../..\\components\\shared\\data-table-row-handler.vue' /* webpackChunkName: "components/shared-data-table-row-handler" */).then(c => c.default || c),
  SharedDecimalInput: () => import('../..\\components\\shared\\decimal-input.vue' /* webpackChunkName: "components/shared-decimal-input" */).then(c => c.default || c),
  SharedDiscountFields: () => import('../..\\components\\shared\\discount-fields.vue' /* webpackChunkName: "components/shared-discount-fields" */).then(c => c.default || c),
  SharedFileUploader: () => import('../..\\components\\shared\\file-uploader.vue' /* webpackChunkName: "components/shared-file-uploader" */).then(c => c.default || c),
  SharedFilterButton: () => import('../..\\components\\shared\\filter-button.vue' /* webpackChunkName: "components/shared-filter-button" */).then(c => c.default || c),
  SharedFilterCard: () => import('../..\\components\\shared\\filter-card.vue' /* webpackChunkName: "components/shared-filter-card" */).then(c => c.default || c),
  SharedGallery: () => import('../..\\components\\shared\\gallery.vue' /* webpackChunkName: "components/shared-gallery" */).then(c => c.default || c),
  SharedImageCombo: () => import('../..\\components\\shared\\image-combo.vue' /* webpackChunkName: "components/shared-image-combo" */).then(c => c.default || c),
  SharedImageUploadCombo: () => import('../..\\components\\shared\\image-upload-combo.vue' /* webpackChunkName: "components/shared-image-upload-combo" */).then(c => c.default || c),
  SharedImageUploaderField: () => import('../..\\components\\shared\\image-uploader-field.vue' /* webpackChunkName: "components/shared-image-uploader-field" */).then(c => c.default || c),
  SharedImageUploader: () => import('../..\\components\\shared\\image-uploader.vue' /* webpackChunkName: "components/shared-image-uploader" */).then(c => c.default || c),
  SharedImageViewer: () => import('../..\\components\\shared\\image-viewer.vue' /* webpackChunkName: "components/shared-image-viewer" */).then(c => c.default || c),
  SharedInfoFields: () => import('../..\\components\\shared\\info-fields.vue' /* webpackChunkName: "components/shared-info-fields" */).then(c => c.default || c),
  SharedLabeledControlFields: () => import('../..\\components\\shared\\labeled-control-fields.vue' /* webpackChunkName: "components/shared-labeled-control-fields" */).then(c => c.default || c),
  SharedLabeledControlLayout: () => import('../..\\components\\shared\\labeled-control-layout.vue' /* webpackChunkName: "components/shared-labeled-control-layout" */).then(c => c.default || c),
  SharedMenuDropdownButton: () => import('../..\\components\\shared\\menu-dropdown-button.vue' /* webpackChunkName: "components/shared-menu-dropdown-button" */).then(c => c.default || c),
  SharedNameValueField: () => import('../..\\components\\shared\\name-value-field.vue' /* webpackChunkName: "components/shared-name-value-field" */).then(c => c.default || c),
  SharedPrimarySubheader: () => import('../..\\components\\shared\\primary-subheader.vue' /* webpackChunkName: "components/shared-primary-subheader" */).then(c => c.default || c),
  SharedPrimaryTitle: () => import('../..\\components\\shared\\primary-title.vue' /* webpackChunkName: "components/shared-primary-title" */).then(c => c.default || c),
  SharedRadioCombo: () => import('../..\\components\\shared\\radio-combo.vue' /* webpackChunkName: "components/shared-radio-combo" */).then(c => c.default || c),
  SharedSecondaryButton: () => import('../..\\components\\shared\\secondary-button.vue' /* webpackChunkName: "components/shared-secondary-button" */).then(c => c.default || c),
  SharedSimpleInfoFields: () => import('../..\\components\\shared\\simple-info-fields.vue' /* webpackChunkName: "components/shared-simple-info-fields" */).then(c => c.default || c),
  SharedSimpleNameValueField: () => import('../..\\components\\shared\\simple-name-value-field.vue' /* webpackChunkName: "components/shared-simple-name-value-field" */).then(c => c.default || c),
  SharedThreeDFileDialog: () => import('../..\\components\\shared\\three-d-file-dialog.vue' /* webpackChunkName: "components/shared-three-dfile-dialog" */).then(c => c.default || c),
  SharedTreeListRow: () => import('../..\\components\\shared\\tree-list-row.vue' /* webpackChunkName: "components/shared-tree-list-row" */).then(c => c.default || c),
  CommonMyProfileAddCardFields: () => import('../..\\components\\common\\my-profile\\add-card-fields.vue' /* webpackChunkName: "components/common-my-profile-add-card-fields" */).then(c => c.default || c),
  CommonMyProfileCreditCardInfoCard: () => import('../..\\components\\common\\my-profile\\credit-card-info-card.vue' /* webpackChunkName: "components/common-my-profile-credit-card-info-card" */).then(c => c.default || c),
  CommonMyProfileEditFormCard: () => import('../..\\components\\common\\my-profile\\edit-form-card.vue' /* webpackChunkName: "components/common-my-profile-edit-form-card" */).then(c => c.default || c),
  CommonMyProfileGeneralInfoCard: () => import('../..\\components\\common\\my-profile\\general-info-card.vue' /* webpackChunkName: "components/common-my-profile-general-info-card" */).then(c => c.default || c),
  CommonMyProfilePasswordChangeCard: () => import('../..\\components\\common\\my-profile\\password-change-card.vue' /* webpackChunkName: "components/common-my-profile-password-change-card" */).then(c => c.default || c)
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
